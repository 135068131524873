'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'
import { isArray, isString, map } from 'lodash-es'
import getRouter, { URLS } from '@router'
import classNames from 'classnames'
import useProgressRouter from '@hooks/useProgressRouter'

export default function Breadcrumb({
  home = false,
  homeLabel = 'Home',
  back = false,
  backLabel = 'Back',
  text,
  h1,
  className,
  children
}) {

  const { backRouter } = useProgressRouter()
  return (
    <section className={classNames('py-14', className)}>
      <ul className='break-words'>
        {
          back && (<BreadcrumbItem back><button type='button' className='button-text-primary' onClick={backRouter}>{backLabel}</button></BreadcrumbItem>)
        }
        {
          home && (<BreadcrumbItem><DisablePrefetchLink prefetch={false} href={getRouter(URLS.homepage)}>{homeLabel}</DisablePrefetchLink></BreadcrumbItem>)
        }
        {
          isArray(children)
            ? (
              map(children, (item, idx) => (
                <BreadcrumbItem key={idx}>{item}</BreadcrumbItem>
              ))
            )
            : (
              <BreadcrumbItem>{children}</BreadcrumbItem>
            )
        }
        {
          text && (<BreadcrumbItem>{text}</BreadcrumbItem>)
        }
        {
          h1 && (<BreadcrumbItem h1>{h1}</BreadcrumbItem>)
        }
      </ul>
    </section>
  )
}

function BreadcrumbItem({
  children,
  h1 = false,
  back = false
}) {
  if (!children) return

  const isElement = isString(children) || isString(children.type)
  const TextContainer = h1 ? 'h1' : 'div'
  return (
    <li className='inline-block child-[i]:last:hidden'>
      <TextContainer className={classNames('inline-block text-sm', isElement ? 'text-neutral-4' : 'button-text-primary')}>{children}</TextContainer>
      <i className={classNames('icon !text-xs text-neutral-4 mx-4 sm:mx-6 inline-block', back ? 'icon-minus rotate-90' : 'icon-arrow-right')}></i>
    </li>
  )
}